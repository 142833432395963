import teal from '@material-ui/core/colors/teal';
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactGA from 'react-ga';
import {connect} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import {ShortcutManager} from 'react-shortcuts';
import keymap from './keymap';

import packageJson from '../package.json';
import {
  clearAuthhUser,
  getAuthorized,
  setAuthLoading,
} from './actions/appAction';
import ErrorStatuses from './components/ErrorStatuses';
import HubCard from './components/Hubcard';
import PageTracker from './components/PageTracker';
import {GOOGLE_AUTH_TOKEN} from './constants/strings';
import Notifications from './modals/Notifications';
import RealtimeAlert from './modals/RealtimeAlert';
import InstancePowerStateScreen from './screens/InstancePowerStateScreen';
import InstanceScreen from './screens/InstanceScreen';
import LoadingScreen from './screens/LoadingScreen';
import SignIn from './screens/SignIn';
import {colors} from './styles';
import storage from './storage';
import {hosts, mergeInstanceWithStatus, getDictInfo} from './util';
import {build_mqtt_connection} from './aws_iot/iot_engine';
import {sleep} from './aws_iot/util';
import {AWS_ACCOUNTS} from './aws_iot/accounts';
import {RTAlertMQTTAcessPoint} from './aws_iot/real_time_status';
import {LuArrowUpDown} from 'react-icons/lu';
import {FaSearch} from 'react-icons/fa';
import {Card, Checkbox, InputBase} from '@material-ui/core';

var mqtt_connections = {};

const theme = createMuiTheme({
  palette: {
    primary: teal,
    error: {500: colors.coral},
    success: {500: colors.turquoiseGreen},
    background: {
      paper: colors.whiteThree,
    },
    text: {
      primary: 'rgba(0, 0, 0, .70)',
      error: colors.coral,
      secondary: colors.warmGreyThree,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Montserrat',
  },
});

const styles = (theme) => ({
  root: {
    color: '#ffffff',
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    backgroundColor: colors.whiteTwo,
    margin: '0',
    border: '0',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'row',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
  },
  header: {
    display: 'inline-flex',
    position: 'relative',
    height: '13.4vh',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#68535c',
    color: 'white',
  },
  headerTitle: {
    position: 'absolute',
    fontSize: '5rem',
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    position: 'absolute',
    right: '1.09375rem',
    gap: '0.3125rem',
  },
  headerUserAuth: {
    fontSize: '0.9375rem',
    bottom: 0,
    padding: 0,
    margin: 0,
  },
  logoutBtn: {
    display: 'flex',
    border: 'none',
    outline: 'none',
    padding: '0.3125rem 0.625rem',
    margin: 0,
    borderRadius: '0.3125rem',
    backgroundColor: '#886d79',
    color: '#fff',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  footer: {
    display: 'inline-flex',
    background: '#68535c',
    lineHeight: '4vh',
    width: '-webkit-fill-available',
    bottom: '0',
    minHeight: '1.25rem',
    fontSize: '2rem',
    justifyContent: 'center',
    color: 'white',
    position: 'fixed',
  },
  hubRoot: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  hubMain: {
    overflow: 'scroll',
    height: '100vh',
  },
  version: {
    position: 'absolute',
    left: theme.spacing.unit,
    bottom: theme.spacing.unit,
    zIndex: 5000,
    color: 'white',
  },
  labTitle: {
    color: colors.black,
    paddingLeft: '1rem',
  },
  searchDiv: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    borderStyle: 'solid',
    borderWidth: '0.125rem',
    height: '2.8em',
    width: '39rem',
    borderColor: colors.pinkishGrey,
    backgroundColor: colors.white,
  },
  sortIcon: {
    color: colors.black,
    height: '3rem',
    paddingLeft: '0.5rem',
  },
  sortPopup: {
    position: 'relative',
    zIndex: 1,
    border: '0.1875rem solid red',
  },
  sortCard: {
    position: 'absolute',
    width: '19rem',
    color: colors.black,
    padding: '0.5rem',
    zIndex: 2,
  },
  sortList: {
    fontSize: '0.8rem',
  },
  searchIcon: {
    marginLeft: '0.625rem',
    marginRight: '0.625rem',
    color: colors.pinkishGrey,
  },
  searchBar: {
    width: '100%',
  },
});

class App extends Component {
  constructor(props) {
    super(props);

    ReactGA.initialize('UA-139130446-1');

    // Set initial state
    this.state = {
      socket: null,
      realtimeAlertVisible: false,
      realtimeAlertPosition: {x: 0, y: 0},
      instancesStatus: [],
      selectedInstance: {},
      jogConfirmation: false,
      troubleshootConfirmation: false,
      jogModal: false,
      resolveCollisionModal: false,
      diagnosticsModal: false,
      offsetTuningModal: false,
      basketModal: false,
      fryerPoseCalibrationModal: false,
      isSorting: false,
      searchLocation: '',
      sortAlphabetically: false,
      sortSeverity: true,
      robotSensorControlModal: false,
    };

    this.shortcutManager = new ShortcutManager(keymap);
  }

  componentDidMount() {
    const {dispatch} = this.props;

    if (storage.get(GOOGLE_AUTH_TOKEN)) {
      dispatch(getAuthorized());
    }

    sessionStorage.clear();
    this.initRealTimeAlerts();
  }

  initRealTimeAlerts = async () => {
    // Building MQTT connections first
    const {appState} = this.props;
    let current_appState = appState;
    while (!storage.get(GOOGLE_AUTH_TOKEN) || !current_appState.authenticated) {
      console.log('Wait for authenticaion');
      const {appState} = this.props;
      current_appState = appState;
      await sleep(1000);
    }

    for (const [account, account_obj] of Object.entries(AWS_ACCOUNTS)) {
      console.log('building AWS IoT Core connection for ', account);
      const on_interrupt_callback = function (code) {
        console.log(
          `AWS IoT Core connection interrupted (${account}) code: ${code}.`
        );
        window.alert(
          `Press OK to refresh the page. Cloud connection is interrupted unexpectedly.`
        );
        window.location.reload();
      };
      const on_resume_callback = function (code, session_present) {
        console.log(
          `AWS IoT Core connection resumed: rc: ${code} existing session: ${session_present}`
        );
      };

      const on_disconnect_callback = function () {
        console.log(`AWS IoT Core connection disconnected.`);
      };
      const client_id = [
        'MSCHub',
        account,
        current_appState.authUser.email,
        Math.floor(Math.random() * 100000000),
      ].join('_');
      await build_mqtt_connection(
        account_obj,
        on_interrupt_callback,
        on_resume_callback,
        on_disconnect_callback,
        client_id
      )
        .then((connection) => {
          mqtt_connections[account] = connection;
          console.log(`Successfully established ${account}`);
        })
        .catch((error) => {
          console.log(`Error while connecting ${account}: ${error}`);
        });
    }

    // Registering Real-time alerts callbacks
    var lastInstanceStatus = {};
    const instanceStatusCallback = (instancesStatus) => {
      this.setState((prev) => ({
        ...prev,
        instancesStatus,
      }));
      lastInstanceStatus = instancesStatus;
    };
    for (const [account, account_obj] of Object.entries(AWS_ACCOUNTS)) {
      while (!mqtt_connections[account]) {
        console.log(`Waiting for ${account} mqtt connection`);
        await sleep(5000);
      }
      const ap = new RTAlertMQTTAcessPoint(mqtt_connections[account]);
      ap.subscribe_diag_min_array(instanceStatusCallback);
    }
  };

  getChildContext() {
    return {shortcuts: this.shortcutManager};
  }
  openJogConfirmation = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Open Jog Confirmation',
    });
    this.setState({jogConfirmation: true});
  };
  closeJogConfirmation = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Close Jog Confirmation',
    });
    this.setState({jogConfirmation: false});
  };
  openJogModal = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Open Jog Modal',
    });
    this.setState({jogModal: true});
  };
  closeJogModal = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Close Jog Modal',
    });
    this.setState({jogModal: false});
  };
  openElevatorModal = () => {
    this.setState({elevatorModal: true});
  };
  closeElevatorModal = () => {
    this.setState({elevatorModal: false});
  };
  openResolveCollisionModal = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Open Collision Modal',
    });
    this.setState({resolveCollisionModal: true});
  };
  closeResolveCollisionModal = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Close Collision Modal',
    });
    this.setState({resolveCollisionModal: false});
  };
  openDiagnosticsModal = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Open Diagnostics Modal',
    });
    this.setState({diagnosticsModal: true});
  };
  closeDiagnosticsModal = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Close Diagnostics Modal',
    });
    this.setState({diagnosticsModal: false});
  };
  openOffsetTuningModal = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Open Offset Tuning Modal',
    });
    this.setState({offsetTuningModal: true});
  };
  closeOffsetTuningModal = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Close Offset Tuning Modal',
    });
    this.setState({offsetTuningModal: false});
  };

  openFryerPoseCalibrationModal = () => {
    this.setState({fryerPoseCalibrationModal: true});
  };
  closeFryerPoseCalibrationModal = () => {
    this.setState({fryerPoseCalibrationModal: false});
  };

  openTroubleshootConfirmation = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Open Troubleshoot Confirmation',
    });
    this.setState({troubleshootConfirmation: true});
  };
  closeTroubleshootConfirmation = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Close Troubleshoot Confirmation',
    });
    this.setState({troubleshootConfirmation: false});
  };

  openRobotSensorControl = () => {
    this.setState({robotSensorControlModal: true});
  };
  closeRobotSensorControl = () => {
    this.setState({robotSensorControlModal: false});
  };

  openBasketModal = () => {
    this.setState({basketModal: true});
  };
  closeBasketModal = () => {
    this.setState({basketModal: false});
  };

  showRealtimeAlert = (instance) => (e) => {
    const x = e.clientX;
    const y = e.clientY;

    this.setState((prev) => ({
      ...prev,
      realtimeAlertPosition: {x, y},
      selectedInstance: instance,
      realtimeAlertVisible: true,
    }));
  };

  closeRealtimeAlert = () => {
    this.setState((prev) => ({
      ...prev,
      realtimeAlertVisible: false,
    }));
  };

  handleClickLogout = () => {
    const {dispatch} = this.props;

    localStorage.removeItem(GOOGLE_AUTH_TOKEN);
    dispatch(setAuthLoading(false));
    dispatch(clearAuthhUser());
    window.location.reload();
  };

  handleSortPopup = () => {
    this.setState((prev) => ({
      ...prev,
      isSorting: !this.state.isSorting,
    }));
  };

  handleClosePopup = () => {
    this.setState((prev) => ({
      ...prev,
      isSorting: false,
    }));
  };

  handleSearch = (searchVal) => {
    this.setState((prev) => ({
      ...prev,
      searchLocation: searchVal,
    }));
  };

  handleSortAlphabetically = () => {
    this.setState((prev) => ({
      ...prev,
      sortAlphabetically: !this.state.sortAlphabetically,
    }));
    this.setState((prev) => ({
      ...prev,
      sortSeverity: !this.state.sortSeverity,
    }));
  };

  handleSortSeverity = () => {
    this.setState((prev) => ({
      ...prev,
      sortSeverity: !this.state.sortSeverity,
    }));
  };

  countRedAlerts = (instance) => {
    let count = 0;
    for (let key in instance) {
      if (instance[key].status === 2 && key !== 'safety_sensor') {
        count++;
      }
    }
    return count;
  };

  render() {
    const {
      closeRealtimeAlert,
      showRealtimeAlert,
      handleClickLogout,
      handleSortPopup,
    } = this;
    const {classes, instances, loadingPowerState, appState} = this.props;
    const {
      realtimeAlertVisible,
      instancesStatus,
      selectedInstance,
      jogConfirmation,
      troubleshootConfirmation,
      jogModal,
      elevatorModal,
      resolveCollisionModal,
      diagnosticsModal,
      offsetTuningModal,
      fryerPoseCalibrationModal,
      basketModal,
      isSorting,
      robotSensorControlModal,
      searchLocation,
      sortAlphabetically,
      sortSeverity,
    } = this.state;

    const is_authenticated =
      appState.authenticated && !!storage.get(GOOGLE_AUTH_TOKEN);
    if (!is_authenticated) {
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/" render={() => <SignIn />} />
          </Switch>
        </BrowserRouter>
      );
    }

    return (
      <BrowserRouter>
        <div>
          <PageTracker instances={instances} />
          <MuiThemeProvider theme={theme}>
            <div className={classes.root}>
              {window.location.pathname === '/' && (
                <Route
                  path="/"
                  children={({match}) => {
                    return (
                      <ErrorStatuses
                        instance={match ? match.params.instance : null}
                        instanceStates={instances}
                      />
                    );
                  }}
                />
              )}
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => (
                    <div className={classes.container}>
                      <div className={classes.header}>
                        <p className={classes.headerTitle}>
                          Miso Support Center
                        </p>
                        <div className={classes.profile}>
                          <p className={classes.headerUserAuth}>
                            {appState.authUser.email}
                          </p>
                          <button
                            className={classes.logoutBtn}
                            onClick={handleClickLogout}
                          >
                            logout
                          </button>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          padding: '0.5rem',
                        }}
                      >
                        <div className={classes.searchDiv}>
                          <FaSearch className={classes.searchIcon} />
                          <InputBase
                            placeholder="Search Location ID"
                            className={classes.searchBar}
                            onChange={(val) =>
                              this.handleSearch(val.target.value)
                            }
                          />
                        </div>
                        <div className={classes.sortPopUp}>
                          <LuArrowUpDown
                            onClick={handleSortPopup}
                            size={23}
                            className={classes.sortIcon}
                          />
                          {isSorting && (
                            <Card className={classes.sortCard}>
                              <p>Sort by:</p>
                              <Checkbox
                                defaultChecked={sortAlphabetically}
                                color="default"
                                onChange={() => {
                                  this.handleSortAlphabetically();
                                  this.handleClosePopup();
                                }}
                              />
                              <span className={classes.sortList}>
                                Alphabetically
                              </span>
                            </Card>
                          )}
                        </div>
                      </div>
                      <div className={classes.hubMain}>
                        {/* Instances that are not lab units */}
                        <div className={classes.hubRoot}>
                          {mergeInstanceWithStatus(
                            instancesStatus,
                            searchLocation,
                            sortAlphabetically,
                            sortSeverity
                          ).map((instance, i) => {
                            const instanceSerial =
                              instance.serial.toLowerCase();
                            if (!instanceSerial.includes('test')) {
                              const numRedAlerts = this.countRedAlerts(
                                instance.status
                              );
                              return (
                                <HubCard
                                  key={i}
                                  siteId={instance.site_id}
                                  instance={instance.host}
                                  email={appState.authUser.email}
                                  serial={instance.serial}
                                  deviceid={instance.deviceid}
                                  info={instance.status}
                                  onClickStatus={showRealtimeAlert(instance)}
                                  numRedAlerts={numRedAlerts}
                                />
                              );
                            }
                          })}
                        </div>

                        {/* Instances that are lab units */}
                        <div className={classes.labTitle}>
                          <h4 style={{color: colors.black}}>Lab Units</h4>
                        </div>
                        <div className={classes.hubRoot}>
                          {mergeInstanceWithStatus(
                            instancesStatus,
                            searchLocation,
                            sortAlphabetically,
                            sortSeverity
                          ).map((instance, i) => {
                            const instanceSerial =
                              instance.serial.toLowerCase();
                            if (instanceSerial.includes('test')) {
                              const numRedAlerts = this.countRedAlerts(
                                instance.status
                              );
                              return (
                                <HubCard
                                  key={i}
                                  siteId={instance.site_id}
                                  instance={instance.host}
                                  email={appState.authUser.email}
                                  serial={instance.serial}
                                  deviceid={instance.deviceid}
                                  info={instance.status}
                                  onClickStatus={showRealtimeAlert(instance)}
                                  numRedAlerts={numRedAlerts}
                                />
                              );
                            }
                          })}
                        </div>
                      </div>
                      <div className={classes.footer}>Miso Robotics, Inc.</div>
                    </div>
                  )}
                />
                <Route
                  path="/:instance"
                  render={({match}) => {
                    const {instance} = match.params;
                    const instanceRedux = instances[instance];
                    const fleetInfo = getDictInfo();
                    const instanceInfo = fleetInfo.find(
                      (obj) => obj.host === instance
                    );
                    if (instanceRedux && !loadingPowerState.status) {
                      return (
                        <>
                          <InstanceScreen
                            instance={instance}
                            instanceRedux={instanceRedux}
                            initRealTimeAlerts={this.initRealTimeAlerts}
                            jogConfirmation={jogConfirmation}
                            troubleshootConfirmation={troubleshootConfirmation}
                            diagnosticsModal={diagnosticsModal}
                            jogModal={jogModal}
                            elevatorModal={elevatorModal}
                            resolveCollisionModal={resolveCollisionModal}
                            offsetTuningModal={offsetTuningModal}
                            fryerPoseCalibrationModal={
                              fryerPoseCalibrationModal
                            }
                            basketModal={basketModal}
                            robotSensorControlModal={robotSensorControlModal}
                            openDiagnosticsModal={this.openDiagnosticsModal}
                            openJogConfirmation={this.openJogConfirmation}
                            openTroubleshootConfirmation={
                              this.openTroubleshootConfirmation
                            }
                            openJogModal={this.openJogModal}
                            openElevatorModal={this.openElevatorModal}
                            openResolveCollisionModal={
                              this.openResolveCollisionModal
                            }
                            openOffsetTuningModal={this.openOffsetTuningModal}
                            openFryerPoseCalibrationModal={
                              this.openFryerPoseCalibrationModal
                            }
                            openBasketModal={this.openBasketModal}
                            openRobotSensorControl={this.openRobotSensorControl}
                            closeDiagnosticsModal={this.closeDiagnosticsModal}
                            closeJogConfirmation={this.closeJogConfirmation}
                            closeTroubleshootConfirmation={
                              this.closeTroubleshootConfirmation
                            }
                            closeJogModal={this.closeJogModal}
                            closeElevatorModal={this.closeElevatorModal}
                            closeResolveCollisionModal={
                              this.closeResolveCollisionModal
                            }
                            closeOffsetTuningModal={this.closeOffsetTuningModal}
                            closeFryerPoseCalibrationModal={
                              this.closeFryerPoseCalibrationModal
                            }
                            closeBasketModal={this.closeBasketModal}
                            closeRobotSensorControl={
                              this.closeRobotSensorControl
                            }
                            user={appState.authUser.email}
                            ipAddress={instanceInfo.ip_address}
                          />
                          <Notifications
                            openJogModal={this.openJogModal}
                            openResolveCollisionModal={
                              this.openResolveCollisionModal
                            }
                            openElevatorModal={this.openElevatorModal}
                            instance={instance}
                            instances={instances}
                            jogModal={jogModal}
                            elevatorModal={elevatorModal}
                            user={appState.authUser.email}
                          />
                        </>
                      );
                    } else if (loadingPowerState.status) {
                      return (
                        <InstancePowerStateScreen
                          powerState={loadingPowerState.text}
                          instance={instance}
                        />
                      );
                    } else {
                      return (
                        <LoadingScreen
                          instance={instance}
                          siteId={instanceInfo.site_id}
                          email={appState.authUser.email}
                          deviceid={instanceInfo.deviceid}
                        />
                      );
                    }
                  }}
                />
              </Switch>
              <RealtimeAlert
                instancesStatus={instancesStatus}
                selectedInstance={selectedInstance}
                position={{...this.state.realtimeAlertPosition}}
                open={realtimeAlertVisible}
                onClose={closeRealtimeAlert}
              />
              <Typography variant="body2" className={classes.version}>
                {packageJson.version}
              </Typography>
            </div>
          </MuiThemeProvider>
        </div>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  instances: PropTypes.object.isRequired,
};
App.childContextTypes = {
  shortcuts: PropTypes.object.isRequired,
};

const mapStateToProps = ({appState, instances, ui}) => ({
  appState,
  instances,
  loadingPowerState: ui.loadingPowerState,
});
const mapDispatch = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

export default connect(mapStateToProps, mapDispatch)(withStyles(styles)(App));
