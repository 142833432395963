import {
  SET_ALARM_STATE,
  SET_BASKET_STATES,
  SET_COOKING_STATES,
  SET_SHELF_STATES,
  SET_FLIPPY_ON,
  SET_MQTT_CONNECTION,
  SET_COOK_PARAMS,
  SET_COOK_TIME_LIST,
  SET_DIAGNOSTICS_STATE,
  SET_FANUC_ALARM_STATE,
  SET_FOODS,
  SET_CAMERA_ID,
  SET_BASKET_LIST,
  SET_FRYER_NUMBER,
  SET_GENERIC_INTERFACE_STATE,
  SET_GET_GRIPPER_SENSOR,
  SET_GET_DISPENSER_LOCK_SENSOR,
  SET_GET_ELEVATOR_SENSOR,
  SET_GRIPPER_STATE,
  SET_HOME_JOINTS,
  SET_HOPPER_STATE,
  SET_ELEVATOR_STATE,
  SET_FRYERS_STATE,
  SET_RELAY_STATE,
  SET_NOTIFICATION_STATE,
  SET_IN_RACK_STATES,
  SET_MISO_MSG,
  SET_OFFSET_DATA,
  SET_OFFSET_UPDATED,
  SET_PNEUM_STATE,
  SET_READY_NEXT_RACK_STATES,
  SET_ROBOT_ARM_STATE,
  SET_ROS_HEADER_TIME_STAMP,
  SET_SCANNER_STATE,
  SET_UI_STATE,
  SET_VERSION_NUMBER,
  SET_AWS_AVAILABLE,
  SET_DISABLED_FRYER_SENSOR_ARRAY,
  SET_NUM_FRYER_SENSORS,
} from './actionTypes';

function dispatchHelper(type, host, payload) {
  return {type, host, payload};
}

export function setAlarmState(...args) {
  return dispatchHelper(SET_ALARM_STATE, ...args);
}

export function setFanucAlarmState(...args) {
  return dispatchHelper(SET_FANUC_ALARM_STATE, ...args);
}

export function setRobotArmState(...args) {
  return dispatchHelper(SET_ROBOT_ARM_STATE, ...args);
}

export function setFoods(...args) {
  return dispatchHelper(SET_FOODS, ...args);
}

export function setCameraID(...args) {
  return dispatchHelper(SET_CAMERA_ID, ...args);
}

export function setBasketList(...args) {
  return dispatchHelper(SET_BASKET_LIST, ...args);
}

export function setHomeJoints(...args) {
  return dispatchHelper(SET_HOME_JOINTS, ...args);
}

export function setScannerState(...args) {
  return dispatchHelper(SET_SCANNER_STATE, ...args);
}

export function setCookParams(...args) {
  return dispatchHelper(SET_COOK_PARAMS, ...args);
}
export function setCookTimeList(...args) {
  return dispatchHelper(SET_COOK_TIME_LIST, ...args);
}

export function setInRackStates(...args) {
  return dispatchHelper(SET_IN_RACK_STATES, ...args);
}

export function setReadyNextRackStates(...args) {
  return dispatchHelper(SET_READY_NEXT_RACK_STATES, ...args);
}

export function setBasketStates(...args) {
  return dispatchHelper(SET_BASKET_STATES, ...args);
}

export function setCookingStates(...args) {
  return dispatchHelper(SET_COOKING_STATES, ...args);
}

export function setShelfStates(...args) {
  return dispatchHelper(SET_SHELF_STATES, ...args);
}

export function setPneumState(...args) {
  return dispatchHelper(SET_PNEUM_STATE, ...args);
}

export function setGripperState(...args) {
  return dispatchHelper(SET_GRIPPER_STATE, ...args);
}

export function setGenericInterfaceState(...args) {
  return dispatchHelper(SET_GENERIC_INTERFACE_STATE, ...args);
}

export function setUIState(...args) {
  return dispatchHelper(SET_UI_STATE, ...args);
}

export function setHopperState(...args) {
  return dispatchHelper(SET_HOPPER_STATE, ...args);
}

export function setElevatorState(...args) {
  return dispatchHelper(SET_ELEVATOR_STATE, ...args);
}

export function setFryersState(...args) {
  return dispatchHelper(SET_FRYERS_STATE, ...args);
}

export function setRelayState(...args) {
  return dispatchHelper(SET_RELAY_STATE, ...args);
}

export function setNotificationState(...args) {
  return dispatchHelper(SET_NOTIFICATION_STATE, ...args);
}

export function setDiagnostics(...args) {
  return dispatchHelper(SET_DIAGNOSTICS_STATE, ...args);
}

export function setFlippyON(...args) {
  return dispatchHelper(SET_FLIPPY_ON, ...args);
}

export function setMQTTConnection(...args) {
  return dispatchHelper(SET_MQTT_CONNECTION, ...args);
}

export function setMisoMsg(...args) {
  return dispatchHelper(SET_MISO_MSG, ...args);
}

export function setFryerNumber(...args) {
  return dispatchHelper(SET_FRYER_NUMBER, ...args);
}

export function setVersionNumber(...args) {
  return dispatchHelper(SET_VERSION_NUMBER, ...args);
}

export function setGetGripperSensor(...args) {
  return dispatchHelper(SET_GET_GRIPPER_SENSOR, ...args);
}

export function setOffsetUpdated(...args) {
  return dispatchHelper(SET_OFFSET_UPDATED, ...args);
}

export function setRosHeaderTimeStamp(...args) {
  return dispatchHelper(SET_ROS_HEADER_TIME_STAMP, ...args);
}

export function setOffsetData(...args) {
  return dispatchHelper(SET_OFFSET_DATA, ...args);
}

export function setAWSAvailable(...args) {
  return dispatchHelper(SET_AWS_AVAILABLE, ...args);
}

export function setDisabledFryerSensorsArray(...args) {
  return dispatchHelper(SET_DISABLED_FRYER_SENSOR_ARRAY, ...args);
}

export function setNumFryerSensors(...args) {
  return dispatchHelper(SET_NUM_FRYER_SENSORS, ...args);
}

export function setGetDispenserLockSensor(...args) {
  return dispatchHelper(SET_GET_DISPENSER_LOCK_SENSOR, ...args);
}

export function setGetElevatorSensor(...args) {
  return dispatchHelper(SET_GET_ELEVATOR_SENSOR, ...args);
}
