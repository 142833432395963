import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Spinner from 'react-spinkit';
import {getInfo} from '../util';

const InstancePowerStateScreen = (props) => {
  const {classes, instance, powerState} = props;

  return (
    <div className={classes.root}>
      <Typography variant="h1" gutterBottom>
        {`Powering ${powerState} ${getInfo(instance).name} ...`}
      </Typography>
      <Spinner
        name="circle"
        style={{
          width: '20em',
          height: '20em',
        }}
      />
    </div>
  );
};

InstancePowerStateScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  instance: PropTypes.string.isRequired,
  powerState: PropTypes.string.isRequired,
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

export default withStyles(styles)(InstancePowerStateScreen);
