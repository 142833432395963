const AWS_F_PROD_ENDPOINT = 'a1102nzy1ha6jt-ats.iot.us-west-2.amazonaws.com'; // F_PROD #669760662316
const AWS_F_QA_ENDPOINT = 'a3e56kl78vwz1x-ats.iot.us-west-2.amazonaws.com'; // F_QA #598103803920
const AWS_F3_QA_ENDPOINT = 'a1d4fkzm33sxb2-ats.iot.us-west-2.amazonaws.com'; // F3_QA #008971643127

const AWS_F_PROD_ACCOUNT_NUMBER = '669760662316';
const AWS_F_QA_ACCOUNT_NUMBER = '598103803920';
const AWS_F3_QA_ACCOUNT_NUMBER = '008971643127';

const AWS_ROLE_NAME = 'F3AuthenticatedMSCUser';
const AWS_REGION = 'us-west-2';

class AWS_ACCOUNT {
  constructor(name, endpoint, number, region, role_name) {
    this.name = name;
    this.endpoint = endpoint;
    this.number = number;
    this.region = region;
    this.role_name = role_name;
    this.cognito_pool_id = 'none';
  }
  get accountInfo() {
    return {
      name: this.name,
      endpoint: this.endpoint,
      number: this.number,
      region: this.region,
      role_name: this.role_name,
      cognito_pool_id: this.cognito_pool_id,
    };
  }
}
const AWS_ACCOUNTS = {
  'F-PROD': new AWS_ACCOUNT(
    'F-PROD',
    AWS_F_PROD_ENDPOINT,
    AWS_F_PROD_ACCOUNT_NUMBER,
    AWS_REGION,
    AWS_ROLE_NAME
  ),
  'F-QA': new AWS_ACCOUNT(
    'F-QA',
    AWS_F_QA_ENDPOINT,
    AWS_F_QA_ACCOUNT_NUMBER,
    AWS_REGION,
    AWS_ROLE_NAME
  ),
  'F3-QA': new AWS_ACCOUNT(
    'F3-QA',
    AWS_F3_QA_ENDPOINT,
    AWS_F3_QA_ACCOUNT_NUMBER,
    AWS_REGION,
    AWS_ROLE_NAME
  ),
};

export {AWS_ACCOUNTS};
