import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';

import {DialogContent} from '@material-ui/core';

import DraggableDialog from '../../components/DraggableDialog';
import JogButton from '../../components/JogButton';
import MisoDialogTitle from '../../components/MisoDialogTitle';
import {disableSlot, jogElevator, mqtt_ap, resetElevator} from '../../ros_init';

import {DISABLING_LEVEL, ELEVATOR_COMMANDS} from '../../enums';
import {callService} from '../../util';

import styles from './styles';

class ElevatorModal extends PureComponent {
  constructor() {
    super();
    this.initialState = {
      resetting: false,
      resetSuccess: false,
      resetFailed: null,
      rebooting: false,
      rebootSuccess: false,
    };
    this.state = this.initialState;
  }

  moveElevator = async (position) => {
    const {instance} = this.props;
    jogElevator[instance].setData = {
      task: position,
    };
    await mqtt_ap.submit_request(jogElevator[instance]).catch((error) => {
      console.log('Submit_Request error: ' + error);
    });
  };

  clearElevatorAlarm = () => {
    const request = {};
    this.setState({resetting: true});
    this.setState({resetFailed: false});
    const allCallback = () => {
      this.setState({resetting: false});
    };
    const successCallback = () => {
      this.setState({resetSuccess: true});
      setTimeout(() => {
        this.setState({resetSuccess: false});
      }, 1500);
    };
    const errorCallback = () => {
      this.setState({resetFailed: true});
      setTimeout(() => {
        this.setState({resetFailed: null});
      }, 1500);
    };
    callService(
      resetElevator,
      this.props.instance,
      request,
      'Clear Elevator Alarms',
      allCallback,
      successCallback,
      errorCallback
    );
  };

  disableElevatorAlarm = (disabled) => {
    const request = {
      user: {
        permissions: DISABLING_LEVEL.UI_USER,
        name: DISABLING_LEVEL.UI_USER,
      },
      component_path: '/agent/elevator_dispenser',
      disabled: disabled,
      reason: 'Toggle Disable Elevator',
    };
    callService(
      disableSlot,
      this.props.instance,
      request,
      'Toggle Disable Elevator'
    );
  };

  render() {
    const {
      classes,
      open,
      jogging,
      onClose,
      instance,
      elevatorState,
      setJogging,
    } = this.props;
    const {resetting, resetSuccess, resetFailed, rebooting, rebootSuccess} =
      this.state;
    const elevatorAlarms =
      elevatorState &&
      elevatorState.notifications &&
      elevatorState.notifications.length > 0 &&
      elevatorState.notifications.filter((alarm) => alarm.code === 'collision');
    let alarmActive = elevatorAlarms && elevatorAlarms.length > 0;

    return (
      <div>
        {jogging && (
          <DraggableDialog
            open={open}
            onClose={onClose}
            paperStyle={{height: '90%', overflow: 'hidden'}}
            aria-labelledby="jog-dialog"
            fullWidth
            maxWidth="md"
          >
            <MisoDialogTitle id="jog-dialog" onClose={onClose}>
              {'Jog Elevator'}
            </MisoDialogTitle>
            <DialogContent className={classes.root}>
              <div className={classes.container}>
                <div style={{position: 'relative'}}>
                  <JogButton
                    label="Send to Dump Position"
                    classes={{
                      root: classes.smallButtonWide,
                    }}
                    onClick={() => {
                      this.moveElevator(ELEVATOR_COMMANDS.DUMP);
                    }}
                    disabled={!alarmActive ? false : true}
                    color={null}
                  ></JogButton>
                  <JogButton
                    label="Send to Home Position"
                    classes={{
                      root: classes.smallButtonWide,
                    }}
                    onClick={() => {
                      this.moveElevator(ELEVATOR_COMMANDS.HOME);
                    }}
                    disabled={!alarmActive ? false : true}
                    color={null}
                  ></JogButton>
                  <JogButton
                    label="Send to Receive Food Position"
                    classes={{
                      root: classes.smallButtonWide,
                    }}
                    onClick={() => {
                      this.moveElevator(ELEVATOR_COMMANDS.RECEIVE_FOOD);
                    }}
                    disabled={!alarmActive ? false : true}
                    color={null}
                  ></JogButton>
                  <JogButton
                    label="Clear Elevator Alarms"
                    classes={{
                      root: classes.smallButtonWide,
                    }}
                    onClick={this.clearElevatorAlarm}
                    disabled={
                      rebooting || rebootSuccess || alarmActive ? false : true
                    }
                    color={alarmActive ? 'secondary' : null}
                    success={resetSuccess}
                    failed={resetFailed}
                  ></JogButton>
                  <JogButton
                    label={
                      elevatorState.disabled
                        ? 'Enable Elevator'
                        : 'Disable Elevator'
                    }
                    classes={{
                      root: classes.smallButtonWide,
                    }}
                    onClick={() =>
                      this.disableElevatorAlarm(
                        elevatorState.disabled ? false : true
                      )
                    }
                    color={null}
                  ></JogButton>
                </div>
              </div>
            </DialogContent>
          </DraggableDialog>
        )}
      </div>
    );
  }
}

ElevatorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  jogging: PropTypes.bool.isRequired,
  elevatorState: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(withRouter(ElevatorModal));
