import {hostsVal} from '../util';

const {MQTTAccessPoint, API_VERSION, QOS} = require('./iot_engine');
const {extract_user_from_mqtt_session} = require('./util');

const MIN_DIAG_ARRAY_TOPIC = 'dt/+/+/+/miso_msgs_diagnostic_minimal_array';
const MOCK_MSC_SITE_ID = 'MSCHub';
const MOCK_MSC_DEVICEID = '000000000000';
const PUBLISH_DIAG_STAT_INT = 60;

// global data structure to maintain latest statuses of all sites
let all_sites_statuses = [];
for (const siteInfo of hostsVal) {
  const name = siteInfo.name;
  const device_id = siteInfo.site_id + '-' + siteInfo.deviceid;
  const slotCount = siteInfo.slotCount;
  const autobasketCount = siteInfo.autobasketCount;
  let data = {
    name,
    device_id,
    slotCount,
    autobasketCount,
    info: {
      safety_sensor: {status: null, stats: null, last_updated: null},
      fryers: {status: null, stats: null, last_updated: null},
      robot_arm: {status: null, stats: null, last_updated: null},
      autobasket: {status: null, stats: null, last_updated: null},
      dispenser: {status: null, stats: null, last_updated: null},
      robot_behaviors: {status: null, stats: null, last_updated: null},
      smart_chef: {status: null, stats: null, last_updated: null},
      perception: {status: null, stats: null, last_updated: null},
      logging: {status: null, stats: null, last_updated: null},
      UI_status: {status: null, stats: null, last_updated: null},
      planning_failures: {status: null, stats: null, last_updated: null},
      execution_failures: {status: null, stats: null, last_updated: null},
      misgrabs: {status: null, stats: null, last_updated: null},
      fryer_slots_disabled_ratio: {
        status: null,
        stats: null,
        last_updated: null,
      },
      shelf_slots_disabled_ratio: {
        status: null,
        stats: null,
        last_updated: null,
      },
      gripper_slot_disabled_ratio: {
        status: null,
        stats: null,
        last_updated: null,
      },
    },
  };
  all_sites_statuses.push(data);
}

const STATUS = {ERROR: 2, WARN: 1, STALE: 3, UNKNOWN: 255, OK: 0};

class RTAlertMQTTAcessPoint extends MQTTAccessPoint {
  constructor(mqttConnection) {
    super(mqttConnection);
    this.topic = MIN_DIAG_ARRAY_TOPIC;
    this.user = extract_user_from_mqtt_session(
      this.connection.config.client_id
    );
  }
  async subscribe_diag_min_array(callback) {
    const diag_min_array_cb = async (topic, payload, dup, qos, retain) => {
      const decoder = new TextDecoder('utf8');
      const json = decoder.decode(payload);
      let message;
      try {
        message = JSON.parse(json);
      } catch (error) {
        console.log(
          `[ERROR] Parsing diag array as JSON failed.\nmsg:${json}\nerror: ${error}`
        );
      }
      const site_id = topic.split('/')[1];
      const common_data = {
        site_id: site_id,
        time: message.header.stamp,
      };
      let site_status = all_sites_statuses.find(
        (site_data) => site_data.device_id === site_id
      );
      if (!site_status) {
        console.debug(`No site status found for ${site_id}!`);
        return;
      }
      site_status = getInstanceStatus(site_status, {
        ...common_data,
        ...get_statuses(
          message.status,
          site_status.slotCount,
          site_status.autobasketCount
        ),
      });
      callback(all_sites_statuses);
    };
    this._subscribe_to_mqtt(this.topic, diag_min_array_cb);
    this.publish_diag_stat();
  }

  async publish_diag_stat() {
    let cur_time = Date.now();
    const stat_topic = `cmd/${MOCK_MSC_SITE_ID}-${MOCK_MSC_DEVICEID}/flippy/${API_VERSION}/stats`;
    const statObj = {
      rTime: cur_time,
      stat: 'diag_ts',
      value: cur_time,
      description: 'Time of subscription to diagnostic topic',
      unit: 'N/A',
      producer: 'msc_hub',
      user: this.user,
      client_id: this.connection.config.client_id,
    };
    await this.connection
      .publish(stat_topic, statObj, QOS)
      .then(() => {
        console.log(`published on ${stat_topic}`);
      })
      .catch((error) => {
        console.log(`failuer:${error}`);
      });
    setTimeout(async () => {
      this.publish_diag_stat();
    }, PUBLISH_DIAG_STAT_INT * 1000);
  }
}

const get_statuses = (array, slotCount, autobasketCount) => {
  let statuses = {};
  const fryerCount = slotCount / 2;

  // NOTE(ST): Adopting with COALESCE(MAX(...),0) or 1 in
  // DIAG_QUERY in backend, non-null statuses are added prior
  // iteration.
  for (let status_ of array) {
    let dynamicHandled = false;

    // Check for dynamic cases first
    for (let i = 1; i <= autobasketCount; i++) {
      const misgrabAutobasketConfigName = `misgrab_autobasket_slot_${i}`;
      const misgrabAutobasketCaseName = `MisGrabDiagnostics: Interface Slot ${i}`;

      if (status_.status_name === misgrabAutobasketCaseName) {
        statuses[misgrabAutobasketConfigName] = status_.level;
        statuses[`${misgrabAutobasketConfigName}LastUpdated`] =
          status_.last_updated;
        dynamicHandled = true;
        break; // Break the inner loop if a dynamic case is handled
      }
    }

    for (let i = 1; i <= slotCount; i++) {
      const misgrabFryerConfigName = `misgrab_fryer_slot_${i}`;
      const misgrabFryerCaseName = `MisGrabDiagnostics: Fryer Slot ${i}`;

      const misgrabShelfConfigName = `misgrab_shelf_slot_${i}`;
      const misgrabShelfCaseName = `MisGrabDiagnostics: Hanger Slot ${i}`;

      if (status_.status_name === misgrabFryerCaseName) {
        statuses[misgrabFryerConfigName] = status_.level;
        statuses[`${misgrabFryerConfigName}LastUpdated`] = status_.last_updated;
        dynamicHandled = true;
        break; // Break the inner loop if a dynamic case is handled
      }
      if (status_.status_name === misgrabShelfCaseName) {
        statuses[misgrabShelfConfigName] = status_.level;
        statuses[`${misgrabShelfConfigName}LastUpdated`] = status_.last_updated;
        dynamicHandled = true;
        break; // Break the inner loop if a dynamic case is handled
      }
    }

    for (let i = 1; i <= fryerCount; i++) {
      const fryerConfigName = `fryer_camera_${i}`;
      const fryerCaseName = `fryer_slot_occupancy_monitor/cameras/fryer_cam_${i}: /fryer_slot_occupancy_monitor/cameras/fryer_cam_${i}/camera_info: FrequencyStatus`;

      if (status_.status_name === fryerCaseName) {
        statuses[fryerConfigName] = status_.level;
        statuses[`${fryerConfigName}LastUpdated`] = status_.last_updated;
        dynamicHandled = true;
        break; // Break the inner loop if a dynamic case is handled
      }
    }

    // Handle static cases if the status is not a dynamic one
    if (!dynamicHandled) {
      switch (status_.status_name) {
        case 'safety_controller_node: e_stop: safety_sensor':
          statuses['e_stop'] = status_.level;
          statuses['e_stopLastUpdated'] = status_.last_updated;
          break;
        case 'safety_controller_node: fryer_door: safety_sensor':
          statuses['fryer_door'] = status_.level;
          statuses['fryer_doorLastUpdated'] = status_.last_updated;
          break;
        case 'safety_controller_node: fryer_lock_1: safety_sensor':
          statuses['fryer_lock_1'] = status_.level;
          statuses['fryer_lock_1LastUpdated'] = status_.last_updated;
          break;
        case 'safety_controller_node: fryer_lock_2: safety_sensor':
          statuses['fryer_lock_2'] = status_.level;
          statuses['fryer_lock_2LastUpdated'] = status_.last_updated;
          break;
        case 'safety_controller_node: fryer_lock_3: safety_sensor':
          statuses['fryer_lock_3'] = status_.level;
          statuses['fryer_lock_3LastUpdated'] = status_.last_updated;
          break;
        case 'alarm_logger Active Robot Arm Collision Alarms':
          statuses['collision_alarms'] = status_.metadata;
          statuses['collision_alarmsLastUpdated'] = status_.last_updated;
          break;
        case 'alarm_logger Active Robot Arm Communication Alarms':
          statuses['communication_alarms'] = status_.level;
          statuses['communication_alarmsLastUpdated'] = status_.last_updated;
          break;
        case 'alarm_logger Active Robot Arm General Alarms':
          statuses['general_alarms'] = status_.level;
          statuses['general_alarmsLastUpdated'] = status_.last_updated;
          break;
        case 'alarm_logger Active Robot Arm Unknown Alarms':
          statuses['unknown_alarms'] = status_.level;
          statuses['unknown_alarmsLastUpdated'] = status_.last_updated;
          break;
        case 'network_dev_monitor: robot: NetworkDevice':
          statuses['controller_not_connected'] = status_.level;
          statuses['controller_not_connectedLastUpdated'] =
            status_.last_updated;
          break;
        case 'robot_agent/fryer_behavior_server: enable_gripper_sensor':
          statuses['gripperSensor'] = status_.level;
          statuses['gripperSensorLastUpdated'] = status_.last_updated;
          break;
        case 'yaskawa/io_relay: Heartbeat':
          statuses['ioRelay'] = status_.level;
          statuses['ioRelayLastUpdated'] = status_.last_updated;
          break;
        case 'yaskawa/joint_state: Heartbeat':
          statuses['jointState'] = status_.level;
          statuses['jointStateLastUpdated'] = status_.last_updated;
          break;
        case 'yaskawa/joint_trajectory_action: Heartbeat':
          statuses['jointTrajectoryAction'] = status_.level;
          statuses['jointTrajectoryActionLastUpdated'] = status_.last_updated;
          break;
        case 'yaskawa/motion_streaming_interface: Heartbeat':
          statuses['MotionStreamingInterface'] = status_.level;
          statuses['MotionStreamingInterfaceLastUpdated'] =
            status_.last_updated;
          break;
        case 'cameras/autobasket_cam_1: /cameras/autobasket_cam_1/camera_info: FrequencyStatus':
          statuses['autobasket_drawer_camera'] = status_.level;
          statuses['autobasket_drawer_cameraLastUpdated'] =
            status_.last_updated;
          break;
        case 'InterfaceSlotStateDiagnostics: Slot Disabled Ratio':
          statuses['autobasket_slots_disabled_ratio'] = status_.level;
          statuses['autobasket_slots_disabled_ratioMetadata'] =
            status_.metadata;
          statuses['autobasket_slots_disabled_ratioLastUpdated'] =
            status_.last_updated;
          break;
        case 'simple_dispenser_driver: Errors':
          statuses['dispenserError'] = status_.level;
          statuses['dispenserErrorLastUpdated'] = status_.last_updated;
          break;
        case 'simple_dispenser_controller: Heartbeat':
          statuses['dispenserControllerHeartbeat'] = status_.level;
          statuses['dispenserControllerHeartbeatLastUpdated'] =
            status_.last_updated;
          break;
        case 'simple_dispenser_driver: Connection':
          statuses['dispenserDriverStatus'] = status_.level;
          statuses['dispenserDriverStatusLastUpdated'] = status_.last_updated;
          break;
        case 'simple_dispenser_driver: Dispenser Temperature':
          statuses['dispenserDriverTemperature'] = status_.level;
          statuses['dispenserDriverTemperatureLastUpdated'] =
            status_.last_updated;
          break;
        case 'gogo_robo_node: Heartbeat':
          statuses['heartbeatOfGogoRoboNode'] = status_.level;
          statuses['heartbeatOfGogoRoboNodeLastUpdated'] = status_.last_updated;
          break;
        case 'plan/1/gogo_robo_node: Heartbeat':
          statuses['heartbeatOfPlanningNode'] = status_.level;
          statuses['heartbeatOfPlanningNodeLastUpdated'] = status_.last_updated;
          break;
        case 'smart_chef_scheduler: Heartbeat':
          statuses['smart_chef_scheduler_heartbeat'] = status_.level;
          statuses['smart_chef_scheduler_heartbeatLastUpdated'] =
            status_.last_updated;
          break;
        case 'smart_chef_robot_arm_agent: Heartbeat':
          statuses['smart_chef_robot_arm_agent_heartbeat'] = status_.level;
          statuses['smart_chef_robot_arm_agent_heartbeatLastUpdated'] =
            status_.last_updated;
          break;
        case 'smart_chef_state_manager: Heartbeat':
          statuses['smart_chef_state_manager_heartbeat'] = status_.level;
          statuses['smart_chef_state_manager_heartbeatLastUpdated'] =
            status_.last_updated;
          break;
        case 'smart_chef_logger: Heartbeat':
          statuses['smart_chef_logger_heartbeat'] = status_.level;
          statuses['smart_chef_logger_heartbeatLastUpdated'] =
            status_.last_updated;
          break;
        case 'alarm_logger: Heartbeat':
          statuses['alarmLoggerHeartbeat'] = status_.level;
          statuses['alarmLoggerHeartbeatLastUpdated'] = status_.last_updated;
          break;
        case 'simple_dispenser_logger: Heartbeat':
          statuses['simpleDispenserLoggerHeartbeat'] = status_.level;
          statuses['simpleDispenserLoggerHeartbeatLastUpdated'] =
            status_.last_updated;
          break;
        case 'yaskawa_motion_logger: Heartbeat':
          statuses['yaskawaMotionLoggerHeartbeat'] = status_.level;
          statuses['yaskawaMotionLoggerHeartbeatLastUpdated'] =
            status_.last_updated;
          break;
        case 'smart_chef_ui_adapter: Heartbeat':
          statuses['uiWaitingForBackend'] = status_.level;
          statuses['uiWaitingForBackendLastUpdated'] = status_.last_updated;
          break;
        case 'PlanningFailureDiagnostics: Planning Failure':
          statuses['planning_failure'] = status_.level;
          statuses['planning_failureLastUpdated'] = status_.last_updated;
          break;
        case 'ExecutionFailureDiagnostics: Execution Failure':
          statuses['execution_failure'] = status_.level;
          statuses['execution_failureLastUpdated'] = status_.last_updated;
          break;
        case 'FryerSlotStateDiagnostics: Slot Disabled Ratio':
          statuses['fryer_slots_disabled_ratio'] = status_.level;
          statuses['fryer_slots_disabled_ratioMetadata'] = status_.metadata;
          statuses['fryer_slots_disabled_ratioLastUpdated'] =
            status_.last_updated;
          break;
        case 'HangerSlotStateDiagnostics: Slot Disabled Ratio':
          statuses['shelf_slots_disabled_ratio'] = status_.level;
          statuses['shelf_slots_disabled_ratioMetadata'] = status_.metadata;
          statuses['shelf_slots_disabled_ratioLastUpdated'] =
            status_.last_updated;
          break;
        case 'GripperSlotStateDiagnostics: Slot Disabled Ratio':
          statuses['gripper_slot_disabled_ratio'] = status_.level;
          statuses['gripper_slot_disabled_ratioMetadata'] = status_.metadata;
          statuses['gripper_slot_disabled_ratioLastUpdated'] =
            status_.last_updated;
          break;
      }
    }
  }
  return statuses;
};

const getInstanceStatus = (site_status, diagnostics_info) => {
  let autobasketCount = site_status.autobasketCount;
  let slotCount = site_status.slotCount;
  let fryerCount = slotCount / 2;
  let data = {...site_status};

  let safety_sensor_data = {
    e_stop: diagnostics_info.e_stop,
    fryer_door: diagnostics_info.fryer_door,
  };
  let safety_sensor_last_updated = {
    e_stop: diagnostics_info.e_stopLastUpdated,
    fryer_door: diagnostics_info.fryer_doorLastUpdated,
  };
  data.info['safety_sensor'] = {
    status: categoryStatus(safety_sensor_data),
    stats: safety_sensor_data,
    last_updated: safety_sensor_last_updated,
  };

  let fryers_data = {
    fryer_lock_1: diagnostics_info.fryer_lock_1,
    fryer_lock_2: diagnostics_info.fryer_lock_2,
  };
  let fryers_last_updated = {
    fryer_lock_1: diagnostics_info.fryer_lock_1LastUpdated,
    fryer_lock_2: diagnostics_info.fryer_lock_2LastUpdated,
  };
  if (diagnostics_info.fryer_lock_3 !== undefined) {
    fryers_data = {
      ...fryers_data,
      ...{
        fryer_lock_3: diagnostics_info.fryer_lock_3,
      },
    };
    fryers_last_updated = {
      ...fryers_last_updated,
      ...{
        fryer_lock_3: diagnostics_info.fryer_lock_3LastUpdated,
      },
    };
  }
  data.info['fryers'] = {
    status: categoryStatus(fryers_data),
    stats: fryers_data,
    last_updated: fryers_last_updated,
  };

  let robot_arm_data = {
    collision_alarms: diagnostics_info.collision_alarms,
    communication_alarms: diagnostics_info.communication_alarms,
    general_alarms: diagnostics_info.general_alarms,
    unknown_alarms: diagnostics_info.unknown_alarms,
    controller_not_connected: diagnostics_info.controller_not_connected,
    gripper_sensor: diagnostics_info.gripperSensor,
    io_relay: diagnostics_info.ioRelay,
    joint_state: diagnostics_info.jointState,
    joint_trajectory_action: diagnostics_info.jointTrajectoryAction,
    motion_streaming_interface: diagnostics_info.MotionStreamingInterface,
  };
  let robot_arm_last_updated = {
    collision_alarms: diagnostics_info.collision_alarmsLastUpdated,
    communication_alarms: diagnostics_info.communication_alarmsLastUpdated,
    general_alarms: diagnostics_info.general_alarmsLastUpdated,
    unknown_alarms: diagnostics_info.unknown_alarmsLastUpdated,
    controller_not_connected:
      diagnostics_info.controller_not_connectedLastUpdated,
    gripper_sensor: diagnostics_info.gripperSensorLastUpdated,
    io_relay: diagnostics_info.ioRelayLastUpdated,
    joint_state: diagnostics_info.jointStateLastUpdated,
    joint_trajectory_action: diagnostics_info.jointTrajectoryActionLastUpdated,
    motion_streaming_interface:
      diagnostics_info.MotionStreamingInterfaceLastUpdated,
  };
  data.info['robot_arm'] = {
    status: categoryStatus(robot_arm_data),
    stats: robot_arm_data,
    last_updated: robot_arm_last_updated,
  };

  let autobasket_data = {};
  let autobasket_last_updated = {};

  if (diagnostics_info.autobasket_slots_disabled_ratioMetadata !== undefined) {
    autobasket_data = {
      autobasket_drawer_camera: diagnostics_info.autobasket_drawer_camera,
    };
    autobasket_last_updated = {
      autobasket_drawer_camera:
        diagnostics_info.autobasket_drawer_cameraLastUpdated,
    };

    var autobasket_disabled_slot_data = convertSlotDisabledMetadataToObject(
      diagnostics_info.autobasket_slots_disabled_ratioMetadata,
      diagnostics_info.autobasket_slots_disabled_ratioLastUpdated
    );
    autobasket_data = {
      ...autobasket_data,
      ...autobasket_disabled_slot_data.data,
    };
    autobasket_last_updated = {
      ...autobasket_last_updated,
      ...autobasket_disabled_slot_data.last_updated_data,
    };
    data.info['autobasket'] = {
      status: categoryStatus(autobasket_data),
      stats: autobasket_data,
      last_updated: autobasket_last_updated,
    };
  }

  let dispenser_data = {};
  let dispenser_last_updated = {};

  if (diagnostics_info.dispenserControllerHeartbeat !== undefined) {
    if (diagnostics_info.dispenserError !== undefined) {
      dispenser_data['dispenser_error'] = diagnostics_info.dispenserError;
      dispenser_last_updated['dispenser_error'] =
        diagnostics_info.dispenserErrorLastUpdated;
    }

    dispenser_data['dispenser_controller_heartbeat'] =
      diagnostics_info.dispenserControllerHeartbeat;
    dispenser_last_updated['dispenser_controller_heartbeat'] =
      diagnostics_info.dispenserControllerHeartbeatLastUpdated;

    dispenser_data['dispenser_driver_status'] =
      diagnostics_info.dispenserDriverStatus;
    dispenser_last_updated['dispenser_driver_status'] =
      diagnostics_info.dispenserDriverStatusLastUpdated;

    dispenser_data['dispenser_driver_temperature'] =
      diagnostics_info.dispenserDriverTemperature;
    dispenser_last_updated['dispenser_driver_temperature'] =
      diagnostics_info.dispenserDriverTemperatureLastUpdated;

    data.info['dispenser'] = {
      status: categoryStatus(dispenser_data),
      stats: dispenser_data,
      last_updated: dispenser_last_updated,
    };

    if (diagnostics_info.heartbeatOfGogoRoboNode !== undefined) {
      let robot_behaviors_data = {
        heartbeat_of_gogo_robo_node: diagnostics_info.heartbeatOfGogoRoboNode,
        heartbeat_of_planning_node: diagnostics_info.heartbeatOfPlanningNode,
      };
      let robot_behaviors_last_updated = {
        heartbeat_of_gogo_robo_node:
          diagnostics_info.heartbeatOfGogoRoboNodeLastUpdated,
        heartbeat_of_planning_node:
          diagnostics_info.heartbeatOfPlanningNodeLastUpdated,
      };
      data.info['robot_behaviors'] = {
        status: categoryStatus(robot_behaviors_data),
        stats: robot_behaviors_data,
        last_updated: robot_behaviors_last_updated,
      };
    }

    if (diagnostics_info.smart_chef_scheduler_heartbeat !== undefined) {
      let smart_chef_data = {
        smart_chef_scheduler_heartbeat:
          diagnostics_info.smart_chef_scheduler_heartbeat,
        smart_chef_robot_arm_agent_heartbeat:
          diagnostics_info.smart_chef_robot_arm_agent_heartbeat,
        smart_chef_ui_adapter_heartbeat:
          diagnostics_info.smart_chef_ui_adapter_heartbeat,
        smart_chef_state_manager_heartbeat:
          diagnostics_info.smart_chef_state_manager_heartbeat,
      };
      let smart_chef_last_updated = {
        smart_chef_scheduler_heartbeat:
          diagnostics_info.smart_chef_scheduler_heartbeatLastUpdated,
        smart_chef_robot_arm_agent_heartbeat:
          diagnostics_info.smart_chef_robot_arm_agent_heartbeatLastUpdated,
        smart_chef_ui_adapter_heartbeat:
          diagnostics_info.smart_chef_ui_adapter_heartbeatLastUpdated,
        smart_chef_state_manager_heartbeat:
          diagnostics_info.smart_chef_state_manager_heartbeatLastUpdated,
      };
      data.info['smart_chef'] = {
        status: categoryStatus(smart_chef_data),
        stats: smart_chef_data,
        last_updated: smart_chef_last_updated,
      };
    }
  }

  if (diagnostics_info.smart_chef_logger_heartbeat !== undefined) {
    let logging_data = {
      smart_chef_logger_heartbeat: diagnostics_info.smart_chef_logger_heartbeat,
      chippy_logger_node_heartbeat: diagnostics_info.alarmLoggerHeartbeat,
      simple_dispenser_logger_heartbeat:
        diagnostics_info.simpleDispenserLoggerHeartbeat,
      yaskawa_motion_logger_heartbeat:
        diagnostics_info.yaskawaMotionLoggerHeartbeat,
    };
    let logging_last_updated = {
      smart_chef_logger_heartbeat:
        diagnostics_info.smart_chef_logger_heartbeatLastUpdated,
      chippy_logger_node_heartbeat:
        diagnostics_info.alarmLoggerHeartbeatLastUpdated,
      simple_dispenser_logger_heartbeat:
        diagnostics_info.simpleDispenserLoggerHeartbeatLastUpdated,
      yaskawa_motion_logger_heartbeat:
        diagnostics_info.yaskawaMotionLoggerHeartbeatLastUpdated,
    };
    data.info['logging'] = {
      status: categoryStatus(logging_data),
      stats: logging_data,
      last_updated: logging_last_updated,
    };
  }

  if (diagnostics_info.uiWaitingForBackend !== undefined) {
    let ui_status_data = {
      ui_waiting_for_backend: diagnostics_info.uiWaitingForBackend,
    };
    let ui_status_last_updated = {
      ui_waiting_for_backend: diagnostics_info.uiWaitingForBackendLastUpdated,
    };
    data.info['UI_status'] = {
      status: categoryStatus(ui_status_data),
      stats: ui_status_data,
      last_updated: ui_status_last_updated,
    };
  }

  if (diagnostics_info.planning_failure !== undefined) {
    let planning_failure_data = {
      planning_failure: diagnostics_info.planning_failure,
    };
    let planning_failure_last_updated = {
      planning_failure: diagnostics_info.planning_failureLastUpdated,
    };
    data.info['planning_failures'] = {
      status: categoryStatus(planning_failure_data),
      stats: planning_failure_data,
      last_updated: planning_failure_last_updated,
    };
  }

  if (diagnostics_info.execution_failure !== undefined) {
    let execution_failure_data = {
      execution_failure: diagnostics_info.execution_failure,
    };
    let execution_failure_last_updated = {
      execution_failure: diagnostics_info.execution_failureLastUpdated,
    };
    data.info['execution_failures'] = {
      status: categoryStatus(execution_failure_data),
      stats: execution_failure_data,
      last_updated: execution_failure_last_updated,
    };
  }

  let slot_misgrab_data = {};
  let slot_misgrab_last_updated = {};

  for (let i = 1; i <= autobasketCount; i++) {
    const misgrabAutobasketConfigName = `misgrab_autobasket_slot_${i}`;

    slot_misgrab_data = {
      ...slot_misgrab_data,
      ...{
        [`${misgrabAutobasketConfigName}`]:
          diagnostics_info[`${misgrabAutobasketConfigName}`],
      },
    };
    slot_misgrab_last_updated = {
      ...slot_misgrab_last_updated,
      ...{
        [`${misgrabAutobasketConfigName}LastUpdated`]:
          diagnostics_info[`${misgrabAutobasketConfigName}LastUpdated`],
      },
    };
  }

  for (let i = 1; i <= slotCount; i++) {
    const misgrabFryerConfigName = `misgrab_fryer_slot_${i}`;

    slot_misgrab_data = {
      ...slot_misgrab_data,
      ...{
        [`${misgrabFryerConfigName}`]:
          diagnostics_info[`${misgrabFryerConfigName}`],
      },
    };
    slot_misgrab_last_updated = {
      ...slot_misgrab_last_updated,
      ...{
        [`${misgrabFryerConfigName}LastUpdated`]:
          diagnostics_info[`${misgrabFryerConfigName}LastUpdated`],
      },
    };
  }

  for (let i = 1; i <= slotCount; i++) {
    const misgrabShelfConfigName = `misgrab_shelf_slot_${i}`;

    slot_misgrab_data = {
      ...slot_misgrab_data,
      ...{
        [`${misgrabShelfConfigName}`]:
          diagnostics_info[`${misgrabShelfConfigName}`],
      },
    };
    slot_misgrab_last_updated = {
      ...slot_misgrab_last_updated,
      ...{
        [`${misgrabShelfConfigName}LastUpdated`]:
          diagnostics_info[`${misgrabShelfConfigName}LastUpdated`],
      },
    };
  }

  data.info['misgrabs'] = {
    status: categoryStatus(slot_misgrab_data),
    stats: slot_misgrab_data,
    last_updated: slot_misgrab_last_updated,
  };

  let perception_data = {};
  let perception_last_updated = {};

  for (let i = 1; i <= fryerCount; i++) {
    const fryerConfigName = `fryer_camera_${i}`;

    perception_data = {
      ...perception_data,
      ...{
        [`${fryerConfigName}`]: diagnostics_info[`${fryerConfigName}`],
      },
    };
    perception_last_updated = {
      ...perception_last_updated,
      ...{
        [`${fryerConfigName}LastUpdated`]:
          diagnostics_info[`${fryerConfigName}LastUpdated`],
      },
    };
  }

  data.info['perception'] = {
    status: categoryStatus(perception_data),
    stats: perception_data,
    last_updated: perception_last_updated,
  };

  if (diagnostics_info.fryer_slots_disabled_ratio !== undefined) {
    var fryer_disabled_slot_data = convertSlotDisabledMetadataToObject(
      diagnostics_info.fryer_slots_disabled_ratioMetadata,
      diagnostics_info.fryer_slots_disabled_ratioLastUpdated
    );
    data.info['fryer_slots_disabled_ratio'] = {
      status: fryer_disabled_slot_data.status,
      stats: fryer_disabled_slot_data.data,
      last_updated: fryer_disabled_slot_data.last_updated_data,
    };
  }

  if (diagnostics_info.shelf_slots_disabled_ratio !== undefined) {
    var shelf_disabled_slot_data = convertSlotDisabledMetadataToObject(
      diagnostics_info.shelf_slots_disabled_ratioMetadata,
      diagnostics_info.shelf_slots_disabled_ratioLastUpdated
    );
    data.info['shelf_slots_disabled_ratio'] = {
      status: shelf_disabled_slot_data.status,
      stats: shelf_disabled_slot_data.data,
      last_updated: shelf_disabled_slot_data.last_updated_data,
    };
  }

  if (diagnostics_info.gripper_slot_disabled_ratio !== undefined) {
    var gripper_disabled_slot_data = convertSlotDisabledMetadataToObject(
      diagnostics_info.gripper_slot_disabled_ratioMetadata,
      diagnostics_info.gripper_slot_disabled_ratioLastUpdated
    );
    data.info['gripper_slot_disabled_ratio'] = {
      status: gripper_disabled_slot_data.status,
      stats: gripper_disabled_slot_data.data,
      last_updated: gripper_disabled_slot_data.last_updated_data,
    };
  }

  data.info['timestamp'] = diagnostics_info.time;
  return data;
};

const categoryStatus = (diagnostics_category_data) => {
  for (let x in STATUS) {
    if (
      Object.entries(diagnostics_category_data).filter(
        ([key, value]) => Number(value) === STATUS[x]
      ).length !== 0
    ) {
      return STATUS[x];
    }
  }
};

const convertSlotDisabledMetadataToObject = (metadata, last_updated) => {
  var newMetadata;
  try {
    newMetadata = JSON.parse(metadata);
  } catch (error) {
    console.log(
      `Parsing metadata JSON failed...\nmsg:${metadata}\nerror: ${error}`
    );
    return;
  }
  // Create a new object with modified keys
  var data = {};
  var last_updated_data = {};
  var status = 0;

  for (var key in newMetadata) {
    if (key !== 'Disabled Slots Ratio') {
      data[key] = newMetadata[key] === 'False' ? 0 : 2;
      last_updated_data[key] =
        newMetadata[key] === 'True' ? last_updated : null;
    }
    if (key === 'Disabled Slots Ratio') {
      status = Number(newMetadata[key]) >= 0.33 ? 2 : 0;
    }
  }
  return {data, last_updated_data, status};
};

export {RTAlertMQTTAcessPoint};
