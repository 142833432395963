import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import ReactGA from 'react-ga';
import {withRouter} from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import DraggableDialog from '../components/DraggableDialog';
import MisoDialogTitle from '../components/MisoDialogTitle';
import {ALARM_ID} from '../enums.js';
import {getGripperState} from '../ros_init.js';
import {callService} from '../util.js';

class JogConfirmation extends PureComponent {
  constructor() {
    super();
    this.initialState = {
      resetting: false,
      resetSuccess: false,
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    const page = this.props.location.pathname;
    ReactGA.modalview(page + '/jog-confirmation');
  }

  getGripper = async () => {
    const request = {};
    ReactGA.event({
      category: 'Gripper State',
      action: 'Get Gripper State',
    });
    await callService(
      getGripperState,
      this.props.instance,
      request,
      'Get Gripper State'
    );
  };

  render() {
    const {classes, open, openJogModal, onClose, jogging, jogRobot, alarms} =
      this.props;
    const {getGripper} = this;
    var window_title = '';
    var window_query = '';

    const robotAlarms = alarms.filter(
      (alarm) =>
        alarm.code === 'collision' &&
        alarm.component_path === '/agent/robot_arm'
    );

    if (!jogging) {
      window_title = 'Jog Robot';
      window_query = 'Are you sure you want to abort action and jog the robot?';
    } else {
      window_title = 'Unjog Robot';
      if (Object.keys(robotAlarms).length > 0) {
        window_query = 'Collision must be resolved before Unjogging.';
      } else {
        window_query = 'Are you sure you want to unjog the robot?';
      }
    }

    const handleJogModalClick = () => {
      getGripper();
      onClose();
      openJogModal();
    };

    return (
      <div>
        <DraggableDialog
          open={open}
          onClose={onClose}
          aria-labelledby="jog-confirmation-dialog"
          fullWidth={true}
        >
          <MisoDialogTitle id="jog-confirmation-dialog" onClose={onClose}>
            {window_title}
          </MisoDialogTitle>
          <p
            style={{
              color: 'red',
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '1.5rem',
            }}
          >
            {window_query}
          </p>
          {Object.keys(robotAlarms).length === 0 ? (
            <div className={classes.buttonContainer}>
              <Button
                onClick={function (event) {
                  jogRobot();
                  onClose();
                  if (!jogging) {
                    openJogModal();
                  }
                }}
              >
                <Typography variant="h4" color="inherit">
                  Yes
                </Typography>
              </Button>
              <Button
                onClick={!jogging ? onClose : handleJogModalClick}
                style={{maxWidth: '60%'}}
              >
                <Typography variant="h4" color="inherit">
                  {jogging ? 'No - Continue to Jogging Modal' : 'No'}
                </Typography>
              </Button>
            </div>
          ) : (
            <div className={classes.buttonContainer}>
              <Button
                onClick={
                  !jogging
                    ? function (event) {
                        jogRobot();
                        onClose();
                        if (!jogging) {
                          openJogModal();
                        }
                      }
                    : handleJogModalClick
                }
                style={{maxWidth: '70%'}}
              >
                <Typography variant="h4" color="inherit">
                  Continue to Jogging Modal
                </Typography>
              </Button>
            </div>
          )}
        </DraggableDialog>
      </div>
    );
  }
}

JogConfirmation.propTypes = {
  classes: PropTypes.object.isRequired,
  jogging: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  openJogModal: PropTypes.func.isRequired,
  jogRobot: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  location: PropTypes.object.isRequired,
};

const styles = {
  buttonContainer: {
    float: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
};

export default withStyles(styles)(withRouter(JogConfirmation));
