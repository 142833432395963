const {v4: uuidv4} = require('uuid');
const {MQTTAccessPoint, API_VERSION, QOS} = require('./iot_engine');
const {stat_obj, sleep} = require('./util');

const CONFIRMATION_SUFFIX = 'confirmation';
const ACK_SUFFIX = 'ack';
const ACK_TIMEOUT = 5;
const CONFIRMATION_TIMEOUT = 60;

var requests_to_ack = {};
var requests_to_confirm = {};

class FlippyPowerMQTTAcessPoint extends MQTTAccessPoint {
  constructor(mqttConnection, thing_name, product, user) {
    super(mqttConnection);
    this.flippy_power_topic_prefix = `cmd/${thing_name}/${product}/${API_VERSION}`;
    this.stat_topic = this.flippy_power_topic_prefix + '/stats';
    this.user = user;
  }

  async requestPowerFlippy(power_request) {
    return new Promise(async (resolve, reject) => {
      const topic = this.flippy_power_topic_prefix + '/flippy_power';
      const rid = uuidv4();
      const request = {
        rid: rid,
        ts: Date.now(),
        power_request: power_request,
      };
      requests_to_ack[rid] = request;
      requests_to_confirm[rid] = ''; // value would be the error message if request fails

      // subscribe to ack
      await this._subscribe_to_mqtt(
        topic + '/' + ACK_SUFFIX,
        (topic, payload, dup, qos, retain) => {
          let acked_time = Date.now();
          const decoder = new TextDecoder('utf8');
          const json = decoder.decode(payload);
          let message;
          try {
            message = JSON.parse(json);
          } catch (error) {
            console.log(
              `[ERROR] Parsing ACK message as JSON...\nmsg:${json}\nerror: ${error}`
            );
            return;
          }
          if (!(message.rid in requests_to_ack)) {
            console.log(
              `Received ACK for a message that is not in requests list. Ignoring ack` +
                `\n${message.rid}`
            );
            return;
          } else {
            this.publish_stat({
              ...{
                id: request.rid,
                request_type: request.power_request,
                ros_topic: request.power_request,
                rTime: request.ts,
                user: this.user,
              },
              ...stat_obj(
                'acked_time',
                'Elapsed round trip from submitting request to receiving ack.',
                'second',
                'msc_instance',
                (acked_time - request.ts) / 1000.0,
                this.connection.config.client_id
              ),
            });
            console.log(`ACKED ${message.rid}`);
            delete requests_to_ack[message.rid];
            this._unsubscribe_to_mqtt(topic);
          }
        }
      )
        .then(() => {
          console.log(`subscribed to ack topic`);
        })
        .catch((error) => reject(error));

      // subscribe to confirmation
      await this._subscribe_to_mqtt(
        topic + '/' + CONFIRMATION_SUFFIX,
        (topic, payload, dup, qos, retain) => {
          let confirmed_time = Date.now();
          const decoder = new TextDecoder('utf8');
          const json = decoder.decode(payload);
          let conf_message;
          try {
            conf_message = JSON.parse(json);
          } catch (error) {
            console.log(
              `[ERROR] Parsing Confirmation message as JSON...\nmsg:${json}\nerror: ${error}`
            );
            return;
          }
          if (!(conf_message.rid in requests_to_confirm)) {
            console.log(
              'Received Confirmation for a message that is not in requests list. Ignoring confirmation'
            );
            return;
          } else {
            this.publish_stat({
              ...{
                id: request.rid,
                request_type: request.power_request,
                ros_topic: request.power_request,
                rTime: request.ts,
                user: this.user,
              },
              ...stat_obj(
                'resp_time',
                'Elapsed round trip from submitting request to receiving ack.',
                'second',
                'msc_instance',
                (confirmed_time - request.ts) / 1000.0,
                this.connection.config.client_id
              ),
            });
            if (conf_message.success) {
              console.log(`CONFIRMED ${conf_message.rid}`);
              delete requests_to_confirm[conf_message.rid];
            } else {
              console.log(`Error from confirmation: ${conf_message.message}`);
              requests_to_confirm[conf_message.rid] = conf_message.message;
            }
            this._unsubscribe_to_mqtt(topic);
          }
        }
      )
        .then(() => {
          console.log(`subscribed to confirmation topic`);
        })
        .catch((error) => reject(error));
      await this._publish_to_mqtt(topic, request)
        .then(() => {
          console.log(`published on ${topic}`);
        })
        .catch((error) => reject(error));
      let now = Date.now();
      while (
        rid in requests_to_ack &&
        (Date.now() - now) / 1000 < ACK_TIMEOUT
      ) {
        console.log('INFO: Waiting for ack...');
        await sleep(100);
      }
      if (rid in requests_to_ack) {
        reject(' > submit_request (ack timeout)');
      }

      while (
        rid in requests_to_confirm &&
        (Date.now() - now) / 1000 < CONFIRMATION_TIMEOUT
      ) {
        if (requests_to_confirm[rid] !== '') {
          reject(
            ` > submit_request (confirmation failed. Error: ${requests_to_confirm[rid]})`
          );
          return;
        }
        console.log('INFO: Waiting for confirmation...');
        await sleep(1000);
      }
      if (rid in requests_to_confirm) {
        reject(' > submit_request (confirmation timeout)');
      }
      resolve();
      // publish stat
    });
  }

  async publish_stat(payload) {
    await this.connection
      .publish(this.stat_topic, payload, QOS)
      .then(() => {
        console.log(`published on ${this.stat_topic}`);
      })
      .catch((error) => {
        console.log(`stat publish failed:${error}`);
      });
  }
}

export {FlippyPowerMQTTAcessPoint};
