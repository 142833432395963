import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import ReactGA from 'react-ga';
import {withRouter} from 'react-router-dom';
import {FaStopCircle} from 'react-icons/fa';

import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import {getAlertDesign, getInfo, IconComponent} from '../../util';
import {colors} from '../../styles';

import styles from './styles';

class HubCard extends PureComponent {
  onClick = () => {
    const {instance, history, email, siteId, deviceid} = this.props;
    let thing_name = siteId + '-' + deviceid;
    if (deviceid == '') {
      thing_name = siteId;
    }
    ReactGA.event({
      category: 'Navigation',
      action: 'Click Hub Card',
      label: instance,
    });
    history.push(`/${instance}`);
  };

  onClickStatus = (disabled) => (e) => {
    e.stopPropagation();
    if (disabled) return;

    this.props.onClickStatus(e);
  };

  render() {
    const {onClick, onClickStatus} = this;
    const {classes, instance, info, numRedAlerts} = this.props;
    const {company, name, location, logo} = getInfo(instance);
    let latest_ts;
    if (info && info.timestamp) {
      const ts = new Date(Date.parse(info.timestamp + 'UTC'));
      latest_ts = ts.toLocaleString();
    } else {
      latest_ts = '00/00/00 00:00';
    }
    const {Icon, alertStyle} = getAlertDesign(info);

    const isDisabled = alertStyle.borderColor === '#aaafba';
    return (
      <ButtonBase
        onClick={onClick}
        focusRipple
        className={classes.root}
        component="div"
      >
        <Paper className={`${classes.paper}`} style={alertStyle}>
          <div className={classes.label}>
            <div className={classes.container}>
              {numRedAlerts > 0 && (
                <div className={classes.numRedAlertsContainer}>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    style={{fontWeight: 'bold'}}
                  >
                    {numRedAlerts} Active Errors
                  </Typography>
                </div>
              )}
              <div className={classes.buttonContainer}>
                <button
                  className={classes.btnRightIcon}
                  onClick={onClickStatus(isDisabled)}
                >
                  <Icon className={classes.rightIcon} />
                </button>
              </div>
            </div>
            {logo && <img src={logo} className={classes.logo} alt="logo" />}
            <Typography variant="h6" align="left">
              {company}
            </Typography>
            <Typography variant="h6" align="left">
              {name}
            </Typography>
            <div className={classes.labelContainer}>
              <Typography variant="subtitle2">{location}</Typography>
              <Typography variant="subtitle2">{latest_ts}</Typography>
            </div>
          </div>
        </Paper>
      </ButtonBase>
    );
  }
}

HubCard.propTypes = {
  classes: PropTypes.object.isRequired,
  instance: PropTypes.string.isRequired,
  instanceRedux: PropTypes.object,
  history: PropTypes.object.isRequired,
};

export default styles(withRouter(HubCard));
