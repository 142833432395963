import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React, {Component, useState} from 'react';
import ReactGA from 'react-ga';
import {Route, Switch, withRouter} from 'react-router-dom';

import DraggableDialog from '../components/DraggableDialog';

import {ALARM_ID, DISABLING_LEVEL, TARGET_STATE, URGENCY_LEVEL} from '../enums';
import {abortTrajectory, abortBehavior, setTargetMode} from '../ros_init';
import {callService, getInfo} from '../util';

const AlertDialog = (props) => {
  const {
    name,
    title,
    body,
    instance,
    onClose,
    onChange,
    onSame,
    sameText,
    jogModal,
    user,
  } = props;
  const info = getInfo(instance);
  const {company, location, serial} = getInfo(instance);
  const instanceName = info.name;

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const toggleJog = (instance, user) => {
    const abort_request = {};
    const jog_request = {
      user: {
        permissions: DISABLING_LEVEL.SUPPORT,
        name: user,
      },
      mode: TARGET_STATE.JOGGING,
      urgency: URGENCY_LEVEL.ASAP,
      pose: '',
      reason: 'support_jogging',
    };
    callService(
      setTargetMode,
      instance,
      jog_request,
      'Set Manual Mode',
      null,
      async () => {
        await callService(
          abortBehavior,
          instance,
          abort_request,
          'Abort Robot Behavior',
          '',
          '',
          async () => {
            await callService(
              abortTrajectory,
              instance,
              abort_request,
              'Abort Robot Trajectory'
            );
          }
        );
      }
    );
  };
  return (
    <DraggableDialog
      open={open}
      aria-labelledby={name + '-dialog-title'}
      aria-describedby={name + '-dialog-description'}
    >
      <DialogTitle id={name + '-dialog-title'}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="notification-dialog-description"
          className="nodrag"
        >
          {body + ' at the following location:'}
          <br /> <br />
          <b>
            {instance}
            <br />
            {company}
            <br />
            {instanceName}
            <br />
            {location}
            <br />
            {serial}
          </b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            jogModal
              ? () => {
                  toggleJog(instance, user);
                  handleClose();
                }
              : () => {
                  handleClose();
                }
          }
        >
          {jogModal ? 'OK' : 'Close'}
        </Button>
        {!jogModal && (
          <Switch>
            <Route
              path={`/${instance}`}
              render={() => (
                <Button
                  onClick={() => {
                    toggleJog(instance, user);
                    handleClose();
                    onSame();
                  }}
                  color="primary"
                  variant="contained"
                >
                  {sameText}
                </Button>
              )}
            />
            <Route
              render={() => (
                <Button
                  onClick={() => {
                    handleClose();
                    onChange();
                  }}
                  color="primary"
                  variant="contained"
                >
                  Go to Installation
                </Button>
              )}
            />
          </Switch>
        )}
      </DialogActions>
    </DraggableDialog>
  );
};
AlertDialog.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  instance: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSame: PropTypes.func,
  sameText: PropTypes.string,
  jogModal: PropTypes.bool,
  jogging: PropTypes.bool,
};

class Notifications extends Component {
  constructor() {
    super();
    this.prevFanucAlarmDict = {};
    this.state = {
      AlarmSeen: {},
      isActive: {},
    };
  }

  closeAlarmModal = (instance) => {
    let newState = this.state.AlarmSeen;
    let newActiveState = this.state.isActive;
    newState[instance] = true;
    newActiveState[instance] = true;

    ReactGA.event({
      category: 'Notifications',
      action: 'Close Alarm Modal',
      label: instance,
    });
    this.setState({
      AlarmSeen: newState,
      isActive: newActiveState,
    });

    setTimeout(() => {
      newActiveState[instance] = false;
      this.setState({
        AlarmSeen: newState,
        isActive: newActiveState,
      });
      // 1 minute?
    }, 60000);
  };

  onInstanceChange = (instance) => {
    const {history} = this.props;
    ReactGA.event({
      category: 'Navigation',
      action: 'Navigate from Notification',
      label: instance,
    });
    history.push(`/${instance}`);
  };

  /**
   * Get title of Dialog
   * @param {string} alarmType
   * @returns
   */
  getDialogDetails = (alarmType) => {
    let title = alarmType;
    const product_title = 'Flippy';

    return {
      title: `${title} Alarm Detected`,
      body: `${product_title} encountered an ${title} Alarm`,
    };
  };

  createAlertDiaog = (
    alertType,
    instance,
    onSame,
    sameText,
    jogModal,
    jogging,
    user
  ) => {
    const keyname = `fanuc-${instance}-${alertType}`;
    const {title, body} = this.getDialogDetails(alertType);
    return (
      <AlertDialog
        key={keyname}
        name={keyname}
        title={title}
        body={body}
        instance={instance}
        onClose={() => this.closeAlarmModal(instance)}
        onChange={() => this.onInstanceChange(instance)}
        onSame={onSame}
        sameText={sameText}
        jogModal={jogModal}
        jogging={jogging}
        user={user}
      />
    );
  };

  render() {
    const {createAlertDiaog} = this;
    const {
      instance,
      instances,
      openResolveCollisionModal,
      openJogModal,
      openElevatorModal,
      jogModal,
      elevatorModal,
      user,
    } = this.props;

    let dialogs = [];
    const instanceRedux = instances[instance];
    if (instanceRedux) {
      const robotAlarms = instanceRedux.notificationState.filter(
        (alarm) =>
          alarm.code === 'collision' &&
          alarm.component_path === '/agent/robot_arm'
      );

      const elevatorAlarms =
        instanceRedux.elevatorState &&
        instanceRedux.elevatorState.notifications &&
        instanceRedux.elevatorState.notifications.length > 0 &&
        instanceRedux.elevatorState.notifications.filter(
          (alarm) => alarm.code === 'collision'
        );

      const motionHoldRobotAlarms = Object.keys(
        instanceRedux.notificationState
      ).reduce(function (alarm, val) {
        if (instanceRedux.notificationState[val].id === ALARM_ID.MOTION_HOLD)
          alarm[val] = instanceRedux.notificationState[val];
        return alarm;
      }, {});

      let jogging;
      let troubleshooting;
      if (instanceRedux.uiState) {
        jogging = instanceRedux.uiState.current_mode === TARGET_STATE.JOGGING;
        troubleshooting = instanceRedux.uiState.troubleshooting;
      }

      const hasRobotAlarm = Object.keys(robotAlarms).length > 0;
      const hasElevatorAlarm = elevatorAlarms && elevatorAlarms.length > 0;
      const hasMotionHoldRobotAlarm =
        Object.keys(motionHoldRobotAlarms).length > 0;

      if (hasRobotAlarm) {
        const newDialog = createAlertDiaog(
          'Robot',
          instance,
          !jogging ? openResolveCollisionModal : openJogModal,
          'Open Resolve Collision Screen',
          jogModal,
          jogging,
          user
        );
        dialogs.push(newDialog);
      }

      if (hasElevatorAlarm && !hasRobotAlarm && !jogModal) {
        const newDialog = createAlertDiaog(
          'Elevator',
          instance,
          openElevatorModal,
          'Open Resolve Collision Screen',
          elevatorModal,
          jogging,
          user
        );
        dialogs.push(newDialog);
      }

      if (hasMotionHoldRobotAlarm) {
        const newDialog = createAlertDiaog(
          'Mis-release or Soft Collision',
          instance,
          jogging ? openJogModal : openResolveCollisionModal,
          'Open Resolve Collision Screen',
          jogModal,
          jogging,
          user
        );
        dialogs.push(newDialog);
      }
    }
    return <div>{dialogs}</div>;
  }
}

Notifications.propTypes = {
  instances: PropTypes.object.isRequired,
  openResolveCollisionModal: PropTypes.func.isRequired,
  openJogModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  jogModal: PropTypes.bool,
};

export default withRouter(Notifications);
